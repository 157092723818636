import polora from ".//assets/polora.png";

function Successful() {
  return (
    <div className='Successful bg-gradient-to-r from-fucktura to-orange-500'>
      <div className='px-10 flex flex-col flex-none items-center justify-center w-screen h-screen gap-10'>
        <img src={polora} class='w-[350px]' alt='Logo' />
        <div className='bg-transparent text-white font-bold rounded-lg p-4 text-6xl placeholder-white text-center'>
          Köszönjük a rendelését!
        </div>
      </div>
    </div>
  );
}

export default Successful;
