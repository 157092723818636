import { createClient } from "@supabase/supabase-js";
const supabaseUrl = "https://fsmkpivaxnxpnevoamen.supabase.co";
const supabaseKey =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImZzbWtwaXZheG54cG5ldm9hbWVuIiwicm9sZSI6ImFub24iLCJpYXQiOjE2NjkyMzY4NDUsImV4cCI6MTk4NDgxMjg0NX0.bBmb0xyeooMB8-pxnswSd0r2z9PxjB5dp2jX-cy2IKA";
const supabase = createClient(supabaseUrl, supabaseKey);

export async function sendOrder({ nev, ital, etel, evoeszkoz }) {
  const { data, error } = await supabase
    .from("Orders")
    .insert([{ Nev: nev, Ital: ital, Fofogas: etel, Evoeszkoz: evoeszkoz }])
    .select();
  if (error) {
    console.error(error);
    return;
  }
  console.log(data);
}
