import Navbar from "./Components/Navbar";

import perla from ".//assets/perla.png";
import cola from ".//assets/cola.png";
import sor from ".//assets/sor.png";
import shot from ".//assets/shot.png";
import gulyas1 from ".//assets/gulyas1.png";
import gulyas2 from ".//assets/gulyas2.png";
import gulyas3 from ".//assets/gulyas3.png";
import gulyas4 from ".//assets/gulyas4.png";
import kezzel from ".//assets/kezzel.png";
import evoeszkoz from ".//assets/evoeszkoz.png";
import { sendOrder } from "./api/supabase";
import Successful from "./Successful";
import { useState } from "react";
import sticker_fekete from "./assets/sticker-fekete.png";

function App() {
  const [menu, setMenu] = useState(1);

  const handleSubmit = (event) => {
    event.preventDefault();
    sendOrder({
      nev: event.target.nev.value,
      ital: event.target.ital.value,
      etel: event.target.etel.value,
      evoeszkoz: event.target.etkezesimod.value,
    });
    setMenu(2);
  };

  return (
    <>
      {menu === 1 && (
        <div className='App bg-gradient-to-r from-fucktura to-orange-500'>
          <Navbar />
          <div className='p-4 pt-[80px] flex flex-col flex-none items-center'>
            <form className='md:max-w-[350px]' onSubmit={handleSubmit}>
              <div className='h-20 w-full rounded-lg shadow-md shadow-black text-white font-bold mb-4 p-[1px] '>
                <input
                  type='text'
                  placeholder='Név:'
                  className='bg-transparent text-white font-bold w-full h-full rounded-lg p-4 text-3xl placeholder-white text-center'
                  name='nev'
                  required
                />
              </div>
              <div className=' w-full h-fit rounded-lg shadow-md shadow-black text-white font-bold mb-4 p-4  flex flex-col flex-1 justify-start items-center'>
                <h1 class='mb-5 text-4xl font-medium text-gray-900 dark:text-white'>Ital</h1>
                <ul class='grid w-full gap-6 grid-cols-2'>
                  <li>
                    <input
                      type='radio'
                      id='perla'
                      name='ital'
                      value='perla'
                      class='hidden peer'
                      required
                    />
                    <label
                      for='perla'
                      class='inline-flex items-center justify-between w-full p-5 text-black border-2 border-black rounded-lg cursor-pointer peer-checked:border-white peer-checked:text-white peer-checked:bg-orange-500 hover:text-gray-600 hover:bg-orange-500 h-[150px]'
                    >
                      <div class='flex flex-1 flex-col justify-center items-center space-y-2'>
                        <div class=' text-lg font-semibold'>Víz</div>
                        <img src={perla} alt='Perla' className='object-contain max-h-[100px]' />
                      </div>
                    </label>
                  </li>
                  <li>
                    <input type='radio' id='cola' name='ital' value='cola' class='hidden peer' />
                    <label
                      for='cola'
                      class='inline-flex items-center justify-between w-full p-5 text-black border-2 border-black rounded-lg cursor-pointer peer-checked:border-white peer-checked:text-white peer-checked:bg-orange-500 hover:text-gray-600 hover:bg-orange-500  h-[150px]'
                    >
                      <div class='flex flex-1 flex-col justify-center items-center space-y-2 '>
                        <div class='text-lg font-semibold'>Cola</div>
                        <img src={cola} alt='Cola' className='object-contain max-h-[100px]' />
                      </div>
                    </label>
                  </li>
                  <li>
                    <input type='radio' id='sor' name='ital' value='sor' class='hidden peer' />
                    <label
                      for='sor'
                      class='inline-flex items-center justify-between w-full p-5 text-black border-2 border-black rounded-lg cursor-pointer peer-checked:border-white peer-checked:text-white peer-checked:bg-orange-500 hover:text-gray-600 hover:bg-orange-500  h-[150px]'
                    >
                      <div class='flex flex-1 flex-col justify-center items-center space-y-2 '>
                        <div class='text-lg font-semibold'>Sör</div>
                        <img src={sor} alt='Sor' className='object-contain max-h-[100px]' />
                      </div>
                    </label>
                  </li>
                  <li>
                    <input type='radio' id='shot' name='ital' value='shot' class='hidden peer' />
                    <label
                      for='shot'
                      class='relative inline-flex items-center justify-between w-full p-5 text-black border-2 border-black rounded-lg cursor-pointer peer-checked:border-white peer-checked:text-white peer-checked:bg-orange-500 hover:text-gray-600 hover:bg-orange-500  h-[150px]'
                    >
                      <div class='flex flex-1 flex-col justify-center items-center space-y-2'>
                        <div class='text-lg font-semibold'>Kicsi erős</div>
                        <img src={shot} alt='Shot' className='object-contain max-h-[100px]' />
                        <div class='absolute w-8 h-8 rounded-full -top-5 -end-3'>
                          <img src={sticker_fekete} alt='sticker-fekete' />
                        </div>
                      </div>
                    </label>
                  </li>
                </ul>
              </div>
              <div className=' w-full h-fit rounded-lg shadow-md shadow-black text-white font-bold mb-4 p-4  flex flex-col flex-1 justify-start items-center'>
                <h1 class='mb-5 text-4xl font-medium text-gray-900 dark:text-white'>Főfogás</h1>
                <ul class='grid w-full gap-6 grid-cols-2'>
                  <li>
                    <input
                      type='radio'
                      id='gulyas1'
                      name='etel'
                      value='gulyas1'
                      class='hidden peer'
                      required
                    />
                    <label
                      for='gulyas1'
                      class='inline-flex items-center justify-between w-full p-5 text-black border-2 border-black rounded-lg cursor-pointer peer-checked:border-white peer-checked:text-white peer-checked:bg-orange-500 hover:text-gray-600 hover:bg-orange-500 h-[150px]'
                    >
                      <div class='flex flex-1 flex-col justify-center items-center space-y-2'>
                        <div class=' text-lg font-semibold'>Gulyás</div>
                        <img src={gulyas1} alt='Gulyas1' className='object-contain max-h-[100px]' />
                      </div>
                    </label>
                  </li>
                  <li>
                    <input
                      type='radio'
                      id='gulyas2'
                      name='etel'
                      value='gulyas2'
                      class='hidden peer'
                      required
                    />
                    <label
                      for='gulyas2'
                      class='inline-flex items-center justify-between w-full p-5 text-black border-2 border-black rounded-lg cursor-pointer peer-checked:border-white peer-checked:text-white peer-checked:bg-orange-500 hover:text-gray-600 hover:bg-orange-500 h-[150px]'
                    >
                      <div class='flex flex-1 flex-col justify-center items-center space-y-2'>
                        <div class=' text-lg font-semibold'>Gulyás</div>
                        <img src={gulyas2} alt='Gulyas2' className='object-contain max-h-[100px]' />
                      </div>
                    </label>
                  </li>
                  <li>
                    <input
                      type='radio'
                      id='gulyas3'
                      name='etel'
                      value='gulyas3'
                      class='hidden peer'
                      required
                    />
                    <label
                      for='gulyas3'
                      class='inline-flex items-center justify-between w-full p-5 text-black border-2 border-black rounded-lg cursor-pointer peer-checked:border-white peer-checked:text-white peer-checked:bg-orange-500 hover:text-gray-600 hover:bg-orange-500 h-[150px]'
                    >
                      <div class='flex flex-1 flex-col justify-center items-center space-y-2'>
                        <div class=' text-lg font-semibold'>Gulyás</div>
                        <img src={gulyas3} alt='Gulyas3' className='object-contain max-h-[100px]' />
                      </div>
                    </label>
                  </li>
                  <li>
                    <input
                      type='radio'
                      id='gulyas4'
                      name='etel'
                      value='gulyas4'
                      class='hidden peer'
                      required
                    />
                    <label
                      for='gulyas4'
                      class='inline-flex items-center justify-between w-full p-5 text-black border-2 border-black rounded-lg cursor-pointer peer-checked:border-white peer-checked:text-white peer-checked:bg-orange-500 hover:text-gray-600 hover:bg-orange-500 h-[150px]'
                    >
                      <div class='flex flex-1 flex-col justify-center items-center space-y-2'>
                        <div class=' text-lg font-semibold'>Gulyás</div>
                        <img src={gulyas4} alt='Gulyas4' className='object-contain max-h-[100px]' />
                      </div>
                    </label>
                  </li>
                </ul>
              </div>

              <div className=' w-full h-fit rounded-lg shadow-md shadow-black text-white font-bold mb-4 p-4  flex flex-col flex-1 justify-start items-center'>
                <h1 class='mb-5 text-4xl font-medium text-gray-900 dark:text-white'>Evési mód</h1>
                <ul class='grid w-full gap-6 grid-cols-2'>
                  <li>
                    <input
                      type='radio'
                      id='evoeszkoz'
                      name='etkezesimod'
                      value='evoeszkoz'
                      class='hidden peer'
                      required
                    />
                    <label
                      for='evoeszkoz'
                      class='inline-flex items-center justify-between w-full p-5 text-black border-2 border-black rounded-lg cursor-pointer peer-checked:border-white peer-checked:text-white peer-checked:bg-orange-500 hover:text-gray-600 hover:bg-orange-500 h-[150px]'
                    >
                      <div class='flex flex-1 flex-col justify-center items-center space-y-2'>
                        <div class='text-lg font-semibold'>Kés-villa</div>
                        <img
                          src={evoeszkoz}
                          alt='Evoeszkoz'
                          className='object-contain max-h-[100px]'
                        />
                      </div>
                    </label>
                  </li>
                  <li>
                    <input
                      type='radio'
                      id='kezzel'
                      name='etkezesimod'
                      value='kezzel'
                      class='hidden peer'
                      required
                    />
                    <label
                      for='kezzel'
                      class='relative inline-flex items-center justify-between w-full p-5 text-black border-2 border-black rounded-lg cursor-pointer peer-checked:border-white peer-checked:text-white peer-checked:bg-orange-500 hover:text-gray-600 hover:bg-orange-500 h-[150px]'
                    >
                      <div class='flex flex-1 flex-col justify-center items-center space-y-2'>
                        <div class=' ext-lg font-semibold'>Natúr</div>
                        <img src={kezzel} alt='Kezzel' className='object-contain max-h-[100px]' />
                        <div class='absolute w-8 h-8 rounded-full -top-5 -end-3'>
                          <img src={sticker_fekete} alt='sticker-fekete' />
                        </div>
                      </div>
                    </label>
                  </li>
                </ul>
              </div>

              <div class='flex flex-row-reverse flex-1 w-full h-14'>
                <button
                  class='bg-orange-500 hover:bg-orange-700 text-white text-3xl font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline border-2 border-black w-full'
                  type='submit'
                >
                  Rendelés leadása
                </button>
              </div>
            </form>
            {/* {Array(20)
          .fill()
          .map((_, i) => (
            <div className='h-20 w-full rounded-lg shadow-md shadow-black p-2 text-white font-bold mb-4 md:max-w-[500px]'>
              {i}
            </div>
          ))} */}
          </div>
        </div>
      )}
      {menu === 2 && <Successful />}
    </>
  );
}

export default App;
